import Modal from "../../components/modal";
import React from "react";

const AheadModal = () => (
  <Modal>
    <h3 className="leads-the-modal">Tempt</h3>
    <ul className="tags">
      <li>Node.js</li>
      <li>React</li>
      <li>PostgreSQL</li>
      <li>Redis</li>
      <li>Shopify</li>
    </ul>
    <p>
      David contacted Squarecat to build a new Shopify app that would change how
      stores and brands offered discounts and sales to their customers. The
      timeline was short and he needed our help to quickly plan and build a
      complex product.
    </p>
    <p>
      There were several different approches we could take with the app. We
      recommended trying a few different ways and with our proven experience
      starting new projects, scoping requirements, and finding solutions to
      little-documented problems we were able to jump in and quickly deliver a
      proof-of-concept.
    </p>
    <p>
      The Shopify developer eco-system provides a great platform for building
      simple apps, but we had several complex challenges with authentication,
      store communication, and data encrichment that we had to find solutions
      to. For each challenge we were able to propose several solutions with pros
      and cons for each of them. David found it very helpful to weigh up the
      different options and make the best decision for Tempt moving forward.
    </p>
    <p>
      We created an elegant app to entice shoppers with dynamically generated
      personalised special offers. We also custom built the store-facing Shopify
      Tempt Admin Portal giving stores complete control over products, pricing,
      and offer types. Tempt was backed by a bespoke and intelligent offer
      suggestion engine which we helped architect, interfaced with, and
      consulted on.
    </p>
  </Modal>
);

export default AheadModal;
